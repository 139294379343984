.MuiColorInput-TextField {
  display: none;
}

.picker-data-container:hover {
  .delete-container {
    opacity: 1;
  }
}

.delete-container {
  opacity: 0;
}

.picker-image-container:hover {
  .delete-image-container {
    opacity: 1;
  }
}

.delete-image-container {
  opacity: 0;
}