.contentContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .cropFactorWrapper {
    border: 1px solid rgba(255, 255, 255, 0.16);
    padding: 16px 16px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
  }

  .cropPositionWrapper {
    border: 1px solid rgba(255, 255, 255, 0.16);
    padding: 16px 16px;
    border-radius: 16px;
    display: flex;
    gap: 24px;
  }

  .pickerWrapper {
    padding: 12px;
    background: rgba(30, 30, 30, 1);
    border-radius: 16px;
    width: 100%;

    .pickerBackdropContainer {
      border-radius: 8px;
      background: #111;
      box-shadow: 0 0 0 1px rgba(240, 240, 240, 0.05);
      height: 56px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .pickerOverlayContainer {
      border-radius: 8px;
      background: #111;
      box-shadow: 0 0 0 1px rgba(240, 240, 240, 0.05);
      height: 56px;
      padding: 12px 12px;
      display: flex;
      align-items: center;
    }
  }
}

.backdropImageContainer {
  border-radius: 8px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.backdropColorContainer {
  border-radius: 8px;
  height: 60%;
  width: 40%;
}

.rightContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 30%;
  align-items: flex-start;

  .generateBtn {
    border-radius: 8px;
    background: #1A1A1A;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    width: 100%;
    cursor: pointer;
  }
}

.generateImageContainer {
  border: 1.5px solid var(--studio-pod-nero-16, rgba(255, 255, 255, 0.16));
  border-radius: 12px;
  overflow: hidden;
}