@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'MatterRegular';
    src: local('MatterRegular'), url(./fonts/Matter-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'MatterMedium';
    src: local('MatterMedium'), url(./fonts/Matter-Medium.ttf) format('opentype');
}