@import 'src/styles/variables';

.container {
  width: 280px;
}

.description {
  font-size: 12px;
  color: rgba(152, 152, 152, 1);
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    width: 400px;
  }
}