@import "src/styles/variables";

.container {
  width: 160px;
  height: 240px;
  border-radius: 8px;
  background: var(--Studio-Pod-SP-Muted-Black, #1A1A1A);
  box-shadow: 0px 0px 0px 1px rgba(240, 240, 240, 0.05);
  padding: 8px;
}

.imgContainer {
  height: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  border-radius: 4px;
  border: 1px solid var(--secondary-nero-16, rgba(255, 255, 255, 0.16));
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
}

.nameText {
  color: rgba(240, 240, 240, 1);
  font-family: $fMatterM;
  font-size: 12px;
}
