@import "styles/variables";

.plusBtn {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: rgba(213, 172, 122, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBtn {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}