@import 'src/styles/variables';

.label {
  color: $light-text;
  font-size: 14px;
  margin-bottom: 10px;
}

.required::after {
  content: "*";
  font-size: 20px;
  color: rgba(240, 240, 240, 1); /* Adjust color as needed */
  font-weight: bold; /* Make it stand out */
  margin-left: 5px; /* Add spacing between text and star */
}

.goldLabel {
  color: $gold-label;
  font-size: 14px;
  margin-bottom: 10px;
}

.input {
  height: 44px;
  width: 100%;
  background-color: rgba(46, 46, 46, 1);
  border-radius: 16px;
  padding-left: 16px;
  color: $input-text-color;

  ::placeholder {
    color: $light-gray-text;
    font-size: 16px;
  }
}

.inputWithData {
  background: #1A1A1A;
  border-radius: 16px;
  padding-left: 16px;
  height: 44px;
  width: 100%;
  color: $input-text-color;
  border: 1px solid #3F3F3F;
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 12px;
}
