@import 'src/styles/variables';

.label {
  color: $light-text;
  font-size: 14px;
  margin-bottom: 10px;
}

.input {
  height: 44px;
  width: 100%;
  background-color: rgb(46, 46, 46);
  border-radius: 16px;
  padding-left: 16px;
  color: $light-text;

  ::placeholder {
    color: $placeholder-color;
    font-size: 16px;
  }
}

.iconContainer {
  position: absolute;
  top: 38px;
  right: 12px;
}