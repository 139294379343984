.container {
  width: 900px;
}

.horizontalLine {
  border-top: 1px solid rgba(46, 46, 46, 1);
}

.imageContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: rgb(37 36 35);
  border-radius: 12px;
}

.imageInnerContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Studio-Pod-Cod-Gray, #111);
  box-shadow: 0px 0px 0px 1px rgba(240, 240, 240, 0.05);
  width: 201px;
}
