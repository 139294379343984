@import "variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  user-select: none;
}

*::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
  outline: none;
  color: rgb(240, 240, 240);
  -webkit-text-fill-color: rgb(240, 240, 240);
  background: white;
  font: inherit; /* Inherit font properties from parent element */
}

input {
  border: none;
  outline: none;
  background: none;
  font: inherit; /* Inherit font properties from parent element */
  padding: 0; /* Adjust padding if needed */
}

html, body {
  overscroll-behavior: none;
  background-color: $dark;
  width: 100%;
  height: 100%;
  font-family: $fMatterM;
}

.border-light {
  border: 1px solid $border-light;
}

.light-gray-text {
  color: $light-gray-text;
}

.gray4-text {
  color: $gray-text;
}

.flex {
  display: flex;
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}


.t-align-right {
  text-align: right;
}

.t-align-left {
  text-align: left;
}

h2 {
  font-size: 32px;
  color: $light-text;
}

h6 {
  font-size: 16px;
  color: $light-text;
}

.mt40 {
  margin-top: 40px;
}

.mb16 {
  margin-bottom: 16px;
}

.mt10 {
  margin-top: 10px;
}

.mt6 {
  margin-top: 6px;
}

.mt16 {
  margin-top: 16px;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.padding8 {
  padding: 8px;
}

.padding-h8 {
  padding-left: 8px;
  padding-right: 8px;
}

.padding24 {
  padding: 24px;
}

.padding-h24 {
  padding-right: 24px;
  padding-left: 24px;
}

.padding16 {
  padding: 16px;
}


.border-radius4 {
  border-radius: 4px;
}

.gap16 {
  gap: 16px;
}

.gap20 {
  gap: 20px;
}

.gap4 {
  gap: 4px;
}

.gap12 {
  gap: 12px;
}

.gap8 {
  gap: 8px;
}

.font14 {
  font-size: 14px;
}

.font24 {
  font-size: 24px;
}

.font21 {
  font-size: 21px;
}

.font13 {
  font-size: 13px;
}

.font12 {
  font-size: 12px;
}

.font11 {
  font-size: 11px;
}

.font10 {
  font-size: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow: scroll;
}

.custom-height {
  height: calc(100% - 76px);;
}

.a-items-c {
  align-items: center;
}

.gap4 {
  gap: 4px;
}

.color-light-gray {
  background-color: rgb(246 246 246);
}

.a-items-start {
  align-items: flex-start;
}

.a-items-flex-end {
  align-items: self-end;
}

.a-items-end {
  align-items: flex-end;
}

.flex-column {
  flex-direction: column;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.gap40 {
  gap: 40px;
}

.full-height {
  height: 100%;
}

.border-radius8 {
  border-radius: 8px;
}

.border-radius16 {
  border-radius: 16px;
}

.border-radius32 {
  border-radius: 32px;
}

.height40 {
  height: 40px;
}

h1 {
  font-size: 43.95px;
  font-weight: 700;
}

h2 {
  font-size: 36.95px;
  font-weight: 700;
}

.btn {
  height: 50px;
  background-color: #ff8a48;
  color: #fff;
  text-align: center;
  letter-spacing: .75px;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px transparent;
  border-radius: 100px;
  justify-content: center;
  align-self: auto;
  align-items: center;
  padding: 10px 25px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  transition: all .275s ease-in-out;
  display: flex;
  width: 200px;
}

.input {
  width: 100%;
  border-radius: 12px;
  border: 2px solid #cbd6e2;
  background:  #f5f8fa;
  padding: 16px 16px;
  color: #33475b;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &::placeholder {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: $silver;
    line-height: normal;
  }

  &:focus {
    border: 2px solid rgba(82,168,236,.8);
  }
}

.input-error {
  border: 2px solid #e12a2a;
}

.error-text {
  color: #e12a2a;
}

.gap20 {
  gap: 20px;
}

.gap24 {
  gap: 24px;
}

.font-s20 {
  font-size: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb40 {
  margin-bottom: 40px;
}

.light-text {
  color: $light-text;
}

.t-align-center {
  text-align: center;
}

.text-with-underline {
  text-decoration-line: underline;
}

@media screen and (max-width: 767px)  {

}

@media screen and (min-width: 768px) and (max-width: 1023px) {
}

@media screen and (min-width: 1024px)  {
}

.bg-dark {
  background-color: #111;
}

.bg-light-dark {
  background-color: #181818;
}

.gold-text {
  color: rgba(213, 172, 122, 1);
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.gray2-text {
  color: rgba(196, 196, 188, 1);
}

.gray3-text {
  color: rgba(152, 152, 152, 1);
}

.bg-dark-gray2 {
  background-color: rgba(196, 196, 188, 1);;
}

.bg-color-green {
  background-color: rgba(97, 193, 131, 1);
}

.green-text {
  color: rgba(97, 193, 131, 1);
}

.orange-text {
  color: rgba(218, 85, 47, 1);
}

.bg-color-orange {
  background-color: rgba(218, 85, 47, 1);
}

.custom-studios-btn {
  height: 40px;
  border-radius: 18px;
  padding: 10px 17px 10px 17px;
}

.black-btn {
  border: 1px solid rgba(61, 61, 61, 1);
  background-color: rgba(17, 17, 17, 1);
  color: rgba(240, 240, 240, 1);

  &:hover {
    background-color: rgb(49, 47, 47);
  }

  &:active {
    background-color: rgb(63, 61, 61);
  }
}

.text-orange {
  color: rgba(218, 85, 47, 1);
}

.red-btn {
  background-color: rgb(194, 31, 31);
  color: rgba(240, 240, 240, 1);

  &:hover {
    background-color: rgb(157, 17, 17);
  }

  &:active {
    background-color: rgb(129, 10, 10);
  }
}

.green-btn {
  background-color: rgba(97, 193, 131, 1);
  color: rgba(240, 240, 240, 1);

  &:hover {
    background-color: rgb(77, 171, 111);
  }

  &:active {
    background-color: rgb(59, 157, 92);
  }
}

.orange-btn {
  background: var(--Secondary-Pizazz, #FE9500);
  color: rgba(246, 246, 246, 1);

  &:hover {
    background-color: #e38805;
  }

  &:active {
    background-color: #cb7b05;
  }
}

.accepted {
  background-color: rgba(97, 193, 131, 1);
  color: rgba(240, 240, 240, 1);
  cursor: initial;

  &:hover {
    background-color: rgba(97, 193, 131, 1);
  }

  &:active {
    background-color: rgba(97, 193, 131, 1);
  }
}

.rejected {
  background-color: #FE9500;
  color: rgba(240, 240, 240, 1);
  cursor: initial;

  &:hover {
    background-color: #FE9500;
  }

  &:active {
    background-color: #FE9500;
  }
}
.review {
  background-color: $btn-bg;
  color: $btn-text-color;
  cursor: initial;

  &:hover {
    background-color: $btn-bg;
  }

  &:active {
    background-color: $btn-bg;
  }
}

.table-header {
  color: rgba(116, 116, 116, 1);
}

.table-item {
  color: rgba(240, 240, 240, 1);
  font-size: 14px;
}

.table-selector-bg {
  background: rgba(26, 26, 26, 1);
  color: rgba(116, 116, 116, 1);
  font-size: 12px;
  border-radius: 8px;
}

.custom-table-row {
  &:hover {
    background-color: rgba(26, 26, 26, 1);
    cursor: pointer;
  }
}

.gray-horizontal-line {
  border-top: 1px solid rgb(46, 46, 46);
}

.small-input {
  height: 37px;
  width: 70px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-60 {
  margin-top: 60px;
}

.weight400 {
  font-weight: 400;
}

.text-white2 {
  color: #F0F0F0;
}

.table-border-b {
  border-bottom: 1px solid var(--studio-pod-nero-16, rgba(255, 255, 255, 0.16));
}

.next-btn-disable {
  border-radius: 8px;
  border: 1px solid #1A1A1A;
  background: transparent;
  width: 32px;
  height: 32px;
}

.next-btn-active {
  border-radius: 8px;
  background: var(--Studio-Pod-SP-Muted-Black, #1A1A1A);
  width: 32px;
  height: 32px;
}

.gray5-text {
  color: rgba(131, 129, 126, 1);
}

.horizontal-line {
  border-top: 1px solid rgba(46, 46, 46, 1);
}

.small-btn {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid var(--secondary-nero-16, rgba(255, 255, 255, 0.16));
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: rgb(49, 47, 47);
  }

  &:active {
    background-color: rgb(63, 61, 61);
  }
}

.small-btn2 {
  cursor: pointer;
  border-radius: 8px;
  background: rgba(26, 26, 26, 1);
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: rgb(49, 47, 47);
  }

  &:active {
    background-color: rgb(63, 61, 61);
  }
}
