@import 'src/styles/variables';

.input {
  height: 44px;
  width: 100%;
  background-color: $dark-gray;
  border-radius: 16px;
  padding-left: 50px;
  color: $light-text;

  ::placeholder {
    color: $placeholder-color;
    font-size: 16px;
  }
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 12px;
}