@import "src/styles/variables";

.drawerContainer {
  display: none;
  background-color: $dark;
  width: 320px;
  border-right: 1px solid $border-light;
  padding: 16px 16px 40px;

  .logoContainer {
    width: 288px;
    padding: 14px 20px;
    margin-bottom: 16px;

    img {
      width: 218px;
    }
  }

  .studioBtnContainer {
    background-color: $dark-gray;
    border-radius: 16px;
    padding: 16px;

    .studioBtn {

      .studioBtnBox {
        width: 36px;
        height: 36px;
        border-radius: 4px;
      }
    }
  }

  .drawerListContainer {
    margin-top: 16px;
    padding: 16px;
    list-style-type: none;
  }

  .drawerListItem {
    line-height: 24px;
    height: 34px;
    width: fit-content;
  }

  .activeDrawerListItem {
    color: $light-text;
    border-bottom: 2px solid $light-border;
  }

  .defaultDrawerListItem {
    color: $light-gray-text;
  }

  .drawerBottomContainer {
    padding: 16px;

    .activeBottomListItem {
      color: $light-text;
    }

    .defaultBottomListItem {
      color: $light-gray-text;
    }

    .drawerBottomListItem {
      line-height: 24px;
      width: fit-content;
    }
  }
}

@media screen and (min-width: 1029px) {
  .drawerContainer {
    display: initial;
  }
}

