@import "src/styles/variables";

.container {
  background-color: $dark;
}

.description {
  font-size: 12px;
  color: rgba(152, 152, 152, 1);
  text-align: center;
}

.rightSide {
  display: none;

  img {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    gap: 24px;
  }

  .rightSide {
    display: flex;
    background-image: url("~assets/images/home-bg.png");
    background-color: #1A1A1A;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;

    img {
      display: flex;
    }
  }
}