@import "styles/variables";

.studioGrayContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 100%;
  border-radius: 24px;
  padding: 16px;
}

.blockContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 24px;
  border-radius: 24px;
}

.statusCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.verticalLine {
  border-right: 2px solid rgba(240, 240, 240, 1);
  height: 12px;
}

.horizontallLine {
  border-bottom: 2px solid rgba(240, 240, 240, 1);
  width: 100%;
}