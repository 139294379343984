.react-datepicker-wrapper {
  background-color: rgb(30, 30, 30);
  width: 120px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .react-datepicker__input-container {
    height: 44px;
    display: flex;
    justify-content: center;

    input {
      font-size: 16px;
      color: rgb(240, 240, 240);
      width: 100%;
      text-align: center;
    }
  }
}