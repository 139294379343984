@import "src/styles/variables";

.imagesContainer {
  width: 100%;
}

.studioContainer {
  width: 50%;
  background-color: $dark-gray;
  border-radius: 24px;
  padding: 16px;
}

.overflowLink {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 155px;
  white-space: nowrap;
  font-size: 11px;
  color: rgba(152, 152, 152, 1);
}

.overflowLink {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  white-space: nowrap;
  font-size: 11px;
  color: rgba(152, 152, 152, 1);
}

.outputsContainer {
  border-radius: 24px;
  border: 1px solid var(--studio-pod-nero-16, rgba(255, 255, 255, 0.16));
  padding: 24px;
  margin-top: 24px;
  gap: 8px;
}

.outputContainer {
  &:hover {
    background-color: rgba(26, 26, 26, 1);
    border-radius: 8px;
  }
}

.addBtnContainer {
  border-radius: 28px;
  border: 4px dashed #1E1E1E;
  padding: 80px 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plusBtn {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: rgba(213, 172, 122, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBtn {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.studioTypeContainer {
  height: 40px;
  width: 120px;
  background-color: rgba(26, 26, 26, 1);
  border-radius: 8px;
  padding: 0 12px;
}

.headshotsContainer {
  border-radius: 12px;
  background: var(--Studio-Pod-Cod-Gray, #111);
  height: 132px;
  padding: 8px;
  box-shadow: 0px 0px 0px 1px rgba(240, 240, 240, 0.05);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.bigImage {
  width: 156px;
  height: 116px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & img {
    width: auto; /* Adjust width as needed */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover;
  }
}

.smallImage {
  width: 80px;
  height: 54px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    width: auto; /* Adjust width as needed */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover;
  }
}
