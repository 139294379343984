@import "src/styles/variables";

.contentContainer {
  padding: 16px 0;
  width: 100%;
  background-color: $dark;
}

@media screen and (min-width: 688px) and (max-width: 689px) {
  .contentContainer {
    width: 688px;
    margin: 16px 0 16px 40px;
  }
}

@media screen and (min-width: 888px) {
  .contentContainer {
    width: 888px;
    margin: 16px 0 16px 70px;
    height: calc(100% - 90px);
  }
}
