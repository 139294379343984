@import "src/styles/variables";

.topContainer {
  padding: 16px 0;
  width: 100%;
  background-color: $dark;
}

.searchInputContainer {
  width: 40%;
}

.menuContainer {
}

.menuAvatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: #4c3f3f;
}

.predictionsContainer {
  border-radius: 16px;
  z-index: 100;
  background-color: rgb(30, 30, 30);
}

.prediction {
  border-radius: 16px;
  padding: 6px 12px 6px 20px;
  color: rgb(116, 116, 116);

  &:hover {
    background-color: rgb(35, 35, 35);
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .topContainer {
    padding: 16px 20px 16px 40px;
  }
}

@media screen and (min-width: 1024px) {
  .topContainer {
    padding: 16px 20px 16px 70px;
  }
}

.bm-burger-bars {
  background: rgba(30, 30, 30, 1);
}

.bm-burger-bars-hover {
  background: rgb(49, 49, 49);
}

.bm-burger-button {
  width: 30px;
  height: 30px;
  position: relative;
  top: 10px;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
}

.bm-menu {
  background: rgba(17, 17, 17, 1);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: 100%;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  padding: 0;
  margin: 0;
}
