@import "src/styles/variables";

.container {
  background-color: $dark-gray;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.imageInnerContainer {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Studio-Pod-Cod-Gray, #111);
  box-shadow: 0px 0px 0px 1px rgba(240, 240, 240, 0.05);
  width: 265px;
}
