@import "styles/variables";

.studioContainer {
  width: 436px;
  background-color: $dark-gray;
  border-radius: 24px;
  padding: 16px;
}

.imagesContainer {
  width: 100%;
}

.overflowLink {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  white-space: nowrap;
  font-size: 11px;
  color: rgba(152, 152, 152, 1);
}

.statusCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.verticalLine {
  border-right: 2px solid rgba(240, 240, 240, 1);
  height: 12px;
}

.studioTypeContainer {
  height: 40px;
  background-color: rgba(26, 26, 26, 1);
  border-radius: 8px;
  padding: 0 12px;
}

.headshotsContainer {
  border-radius: 12px;
  background: var(--Studio-Pod-Cod-Gray, #111);
  height: 132px;
  padding: 8px;
  box-shadow: 0px 0px 0px 1px rgba(240, 240, 240, 0.05);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.bigImage {
  width: 156px;
  height: 116px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & img {
    width: auto; /* Adjust width as needed */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover;
  }
}

.smallImage {
  width: 80px;
  height: 54px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    width: auto; /* Adjust width as needed */
    height: 100%; /* Maintain aspect ratio */
    object-fit: cover;
  }
}
