@import 'src/styles/variables';

.btn {
  height: 100%;
  background-color: $btn-bg;
  border-radius: 16px;
  padding: 8px 12px;
  border: 1px solid $border-light;
  gap: 6px;
  color: $btn-text-color;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $fMatterM;

  &:hover {
   background-color: $btn-hover;
  }

  &:active {
    background-color: $btn-active;
  }

  &:disabled {
    background-color: $btn-disabled;
  }
}