.grayContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 60%;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  margin-top: 24px;
  align-items: start;
  flex-direction: column;
  gap: 20px;
}

.contactListContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 36%;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  margin-top: 24px;
  align-items: start;
  flex-direction: column;
}