.outputWrapper {
  border: 0.5px solid rgba(116, 116, 116, 1);
  border-radius: 16px;
  display: flex;

  &:hover {
    background-color: rgba(26, 26, 26, 1);;
    cursor: pointer;
  }

  .imgContainer {
    padding: 8px;
    height: 100px;
    border-right: 0.5px solid rgba(116, 116, 116, 1);
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      border-radius: 12px;
    }
  }

  .nameContainer {
    padding: 0 16px;
    border-right: 0.5px solid rgba(116, 116, 116, 1);
    width: 55%;
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .verticalLine {
    border-right: 2px solid rgba(240, 240, 240, 1);
    height: 12px;
  }

  .sizeContainer {
    border-right: 0.5px solid rgba(116, 116, 116, 1);
    width: 20%;
    padding: 0 16px;
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .visibilityContainer {
    width: 15%;
    padding: 0 16px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}