@import "styles/variables";

.studioGrayContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 100%;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  justify-content: space-around;
}

.blockContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  padding: 24px;
  border-radius: 24px;
}

.DatePicker {
  background-color: rgb(30, 30, 30);
  color: rgb(240, 240, 240);

  .css-1dslbfu-MuiInputBase-root-MuiOutlinedInput-root {
    background-color: red;
  }
}