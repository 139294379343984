.container {
  width: 280px;
}

@media screen and (min-width: 768px) {
  .container {
    width: 400px;
  }
}

.grayContainer {
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 600px;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  margin-top: 24px;
  align-items: start;
  flex-direction: column;
  margin-bottom: 20px;
}
