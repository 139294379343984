$silver: #dee7e7;
$dark: rgba(17, 17, 17, 1);
$border-light: rgba(255, 255, 255, 0.1);
$dark-gray: rgba(30, 30, 30, 1);
$light-text: rgba(240, 240, 240, 1);
$light-gray-text: rgba(116, 116, 116, 1);
$light-border: rgba(240, 240, 240, 1);
$placeholder-color: rgba(131, 129, 126, 1);
$btn-bg: rgba(246, 246, 246, 1);
$gray-text: rgba(131, 129, 126, 1);
$btn-text-color: rgba(26, 26, 26, 1);
$btn-hover: rgba(235, 235, 235, 1);
$btn-active: rgba(225, 225, 225, 1);
$btn-disabled: rgba(246, 246, 246, 0.3);
$gold-label: rgba(213, 172, 122, 1);
$input-text-color: #F6F6F6;

$fMatterR: 'MatterRegular', sans-serif;
$fMatterM: 'MatterMedium', sans-serif;

