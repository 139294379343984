.table-item:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2%;
}

.table-item:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2%;
}

.table-item:nth-child(3) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2%;
}

.table-item:nth-child(4) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2%;
}

.table-item:nth-child(5) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2%;
}

//.table-item {
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//}